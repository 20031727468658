import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Why Men Are Getting More Cosmetic Dental | Cosmetic Dentistry" 
    description="From becoming more attractive to the opposite sex, to having more confidence in the workplace. Find out why more men are getting porcelain veneers in 2019." 
    keywords="cosmetic dentistry, cosmetic dentistry services" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Why men are getting more cosmetic dental</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>A smile is where a relationship starts, whether it is personal or professional, and more than even have realized that their dental looks are important. We live in an ultra competitive world, no matter if we are looking at the business market or the dating market, and the one thing that can make you stand out is the first impression. In this article we are going to take a bit of a deep dive into the human psyche and answer the question that has been on the minds of many, why are men getting more cosmetic dental work done?
            </p>
            <img src={ require("../../images/desktop/Cosmetic Image men .png" )} alt="Blog Banner" className="bl__img" />
            <h3>It is less taboo</h3>
            <p>Would you be able to even think of a man getting a cosmetic dental surgery twenty-five years ago? The answer is no, but in today’s cosmetic world, it is just another surgery. There is no difference between it and a necessary procedure, however, today, cosmetic surgeries are no longer taboo in society.  This is due to the openness of success stories on social media, and the realization by men that they can control a part of their looks with a simple and safe procedure!
            </p>
            <h3>The ‘Selfie’ Culture has resulted in a spike in cosmetic dentistry</h3>
            <p>Look at your timeline on Instagram, and tell me what you can see on every photo with people in them? Well once you get through the travel and dog pictures, you will notice that people are always smiling. This is because we live in a ‘selfie’ culture. We want to take photos of ourselves and share them for the world to see, and one of the key aspects of this sharing is the ability to show off a great smile. Cosmetic dentistry allows to happen, and for men, the realization that it only takes a quick procedure to ensure a great smile has only been recently discovered.
            </p>
            <img src={ require("../../images/desktop/Cosmetic Image men 2 .png" )} alt="Men Cosmetic Dental" className="bl__img" />
            <h3>Brotox has become a thing, which only has opened more opportunities for cosmetic dentistry</h3>
            <p>When you consider the changes in society that Botox has had on society, one must wonder what we did before this wonder procedure was discovered. More and more men have seen the benefits of utilising Botox, and the results have been more men discovering procedures like cosmetic dentistry. In fact, there are several dentistry clinics who now offer Botox, as well as, cosmetic dentistry options for patients.
            </p>
            <h3>Success Rates</h3>
            <p>When it comes to cosmetic dentistry, the success rates are truly phenomenal. As more men test out these types of procedures, and their friends find out the results, it only increases the good press around the treatment. We have seen hundreds of men come into our clinic because their friends had a surgery, and we love it!
            </p>
            <h3>Final Thoughts</h3>
            <p>It is a simple fact that more men then ever before are getting cosmetic dental treatments, and this article took a bit of a dive into some of the reasoning. It could be the fact that cosmetic dental surgeries are less taboo, it could be the influence of the selfie culture, or even brotox, or the simple success rate of the surgeries. But one thing we do know is if you are looking for more information or wish to chat with a surgeon about your options, the team at The Smile Stylist cannot wait to help!
            </p>
          </div>
          <div className="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img alt="Virtual Smile Makeover" src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage